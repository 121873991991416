import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonText,
  IonToolbar,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonToast,
} from "@ionic/react";
import ContentWrapper from "../../../components/ContentWrapper";
import UserInfo from "../../../types/user.types";
import _ from "lodash";
import { useFirestore } from "reactfire";
import { doc, updateDoc } from "@firebase/firestore";
import { useFunctions } from "reactfire";
import { httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { v5 as uuidv5 } from "uuid";
import { closeSharp } from "ionicons/icons";
import FormInput from "../../../components/Input/FormInput";
import { useForm } from "react-hook-form";
import SearchBar from "../../../components/Search";
import { useState } from "react";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import Loading from "../../../components/Loading";

interface UserInfoC extends UserInfo {
  createdAt: string;
}

interface ViewProfileProps {
  data: UserInfoC;
  dismiss: () => void
}

const Account: React.FC<ViewProfileProps> = ({ data: profileUser, dismiss: dismissAcountTab }) => {
  const functions = useFunctions();

  const setClaims = httpsCallable<
    { uid: string; claims: Object },
    {
      status: "Error" | "Success";
      note: string;
    }
  >(functions, "user-setClaims");

  const deleteMember = httpsCallable<
    { uid: string },
    {
      status: "Error" | "Success";
      note: string;
    }
  >(functions, "user-deleteMember");

  const deleteMemberCheck = httpsCallable<
    { uid: string },
    {
      status: "Error" | "Success";
      memberCount: number;
      dgroupCount: number;
    }
  >(functions, "user-deleteMemberCheck");

  const firestore = useFirestore();
  const userRef = doc(firestore, "users", `${_.get(profileUser, "uid", "")}`);

  const [presentL, dismissL] = useIonLoading();
  const [presentT] = useIonToast();
  const [presentA] = useIonAlert();

  const handleDismiss = () => {
    dismissM();
  };

  const [presentM, dismissM] = useIonModal(FindLeader, {
    profileUser,
    onDismiss: handleDismiss,
  });

  if (!profileUser) {
    return <></>;
  }

  const changePin = () => {
    presentL();
    if (profileUser.uid) {
      updateDoc(userRef, {
        pin: uuidv5("0000", profileUser.uid),
      })
        .then((d) => {
          setTimeout(() => {
            dismissL();
            presentT({
              message: "Pin reset to 0000 Successfully.",
              duration: 700,
            });
          }, 500);
        })
        .catch((d) =>
          setTimeout(() => {
            dismissL();
            presentT({
              message: "Fail to Reset Pin.",
              duration: 700,
              color: "danger",
            });
          }, 500)
        );
    }
  };

  const setAdmin = () => {
    presentL();
    setClaims({
      uid: profileUser.uid!,
      claims: {
        admin: true,
      },
    })
      .then((d) =>
        setTimeout(() => {
          dismissL();
          presentT({
            message: "Set as Admin Successfully.",
            duration: 700,
          });
        }, 500)
      )
      .catch((d) =>
        setTimeout(() => {
          dismissL();
          presentT({
            message: "Failed to set Admin.",
            duration: 700,
            color: "danger",
          });
        }, 500)
      );

    console.log(getAuth().currentUser);
  };

  const deleteM = () => {
    presentL();
    deleteMemberCheck({
      uid: profileUser.uid!,
    })
      .then((d) =>
        setTimeout(() => {
          console.log(d);

          if (d.data.memberCount > 0) {
            dismissL();
            presentA({
              message: `Can't delete member. He/She has ${d.data.memberCount} member(s).`,
              buttons: ["OK"],
            });
          } else {
            dismissL();
            presentA({
              message: `Are you sure you want to delete?`,
              buttons: [
                {
                  text: "Yes",
                  handler: () => {
                    presentL();
                    deleteMember({
                      uid: profileUser.uid!,
                    }).then(() =>
                      setTimeout(() => {
                        dismissL();
                        presentT({
                          message: "Member Deleted Successfully.",
                          duration: 700,
                        }).catch(() =>
                          setTimeout(() => {
                            dismissL();
                            presentT({
                              message: "Failed to delete member.",
                              duration: 700,
                              color: "danger",
                            });
                          }, 500)
                        );
                        setTimeout(() => {
                          dismissAcountTab()
                        }, 1000)
                      }, 3000)
                    )},
                },
                "No",
              ],
            });
          }
        }, 500)
      )
      .catch((d) =>
        setTimeout(() => {
          dismissL();
          presentT({
            message: "Failed to check.",
            duration: 700,
            color: "danger",
          });
        }, 500)
      );

    console.log(getAuth().currentUser);
  };

  const ChangeLeader = () => {
    presentM();
  };

  return (
    <ContentWrapper menu="app-menu">
      <div
        style={{
          margin: "20px 16px",
        }}
      >
        <IonButton expand="block" onClick={() => setAdmin()}>
          Set Admin
        </IonButton>
      </div>
      <div
        style={{
          margin: "20px 16px",
        }}
      >
        <IonButton expand="block" onClick={() => changePin()}>
          Reset Pin
        </IonButton>
      </div>

      <div
        style={{
          margin: "0 16px",
        }}
      >
        <IonButton expand="block" onClick={() => ChangeLeader()}>
          Change Leader
        </IonButton>
      </div>

      <div
        style={{
          margin: "20px 16px",
        }}
      >
        <IonButton color="danger" expand="block" onClick={() => deleteM()}>
          Delete Member
        </IonButton>
      </div>
    </ContentWrapper>
  );
};

const FindLeader: React.FC<{
  profileUser: UserInfoC
  onDismiss: () => void;
}> = ({ onDismiss, profileUser }) => {
  const [searchName, setsearchName] = useState("");

  const [presentL, dismissL] = useIonLoading();
  const [presentT] = useIonToast();
  const [presentA] = useIonAlert();

  const functions = useFunctions();

  const changeLeader = httpsCallable<
    { uid: string, newDLeader: string },
    {
      status: "Error" | "Success";
      note: string;
    }
  >(functions, "user-changeLeader");

  const [searchL, {
    loading,
    error,
    data = { getBackendMemberSearch: [] },
  }] = useLazyQuery<{
    getBackendMemberSearch: UserInfoC[];
  }>(
    gql`
      query Query($name: String) {
        getBackendMemberSearch(name: $name) {
          uid
          firstName
          birthday
          lastName
          displayName
          ccf_location
          phone
          accepted
          postalCode
          prefecture
          town
          city
          addressLine
          username
          pin
          createdAt
        }
      }
    `,
  );

  const changeL = (u: UserInfoC) => {
    presentL()

    changeLeader({
      uid: profileUser.uid!,
      newDLeader: u.uid!,
    })
    .then((d) => {
      if (d.data.status === 'Success') {
        setTimeout(() => {
          onDismiss()
          dismissL();
          presentT({
            message: `Change to ${u.displayName} succesfully.`,
            duration: 1500,
          });
        }, 500);
      } else {
        setTimeout(() => {
          dismissL();
          presentT({
            message: `Fail to change leader. ${_.get(d, 'data.note', '')}`,
            duration: 2000,
            color: "danger",
          });
        }, 500)
      }
    })
    .catch((d) =>
      setTimeout(() => {
        dismissL();
        presentT({
          message: "Fail to change leader.",
          duration: 700,
          color: "danger",
        });
      }, 500)
    );

    console.log(u.uid)
  }


  return (
    <>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon src={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div
          style={{
            padding: "10px 10px 0 10px",
          }}
        >
          <SearchBar
            onSearch={(t) => {
              searchL({
                variables: {
                  name: t
                }
              })
            }}
          />

          {loading && <Loading />}
          <IonList style={{
            paddingRight: 10
          }}>
            {!loading &&
              data &&
              data.getBackendMemberSearch &&
              data.getBackendMemberSearch.map((u) => {
                if (profileUser.uid === u.uid) {
                  return <></>
                }
                return <IonItem>
                  
                  <IonText>
                  {u.displayName}
                  <IonLabel style={{
                    fontSize: 9
                  }}>
                 
                  {u.ccf_location}
                  </IonLabel>
                   
                    
                  </IonText>

                  {
                    profileUser.dGroupLeader !== u.uid ? <IonButton slot="end" onClick={() => changeL(u)} >
                    Change
                  </IonButton> : <IonButton slot="end" onClick={() => changeL(u)} color="dark" disabled>
                    Current
                  </IonButton>
                  }
                  
                </IonItem>;
              })}
          </IonList>
        </div>
      </IonContent>
    </>
  );
};

export default Account;
