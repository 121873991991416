import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTab,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import ContentWrapper from "../../components/ContentWrapper";
import Header from "../../components/Header";
import Table from "../../components/Table/Table";
import DateRangeInput from "../../components/Input/DateRangeInput";
import { gql, useQuery } from "@apollo/client";
import dGroup, {
  dGroupCategoryEnum,
  dGroupFrequncyEnum,
  dGroupLocationEnum,
  dGroupReport,
  dgroupReportQL,
  dGroupTypeEnum,
} from "../../types/dgroup.types";
import moment from "moment";
import { parsePhoneNum } from "../../functions/parsePhoneNum";

import {
  build,
  calendar,
  closeSharp,
  documentText,
  home,
  images,
  informationCircle,
  map,
  pencil,
  people,
  person,
  personCircle,
} from "ionicons/icons";
import _ from "lodash";
import { StateContext } from "../../context/state.context";
import UserInfo from "../../types/user.types";
import ModalTab from "../../components/Modal/ModalTab";
import ViewProfile from "./Modal/Info";
import EditDetail from "./Modal/EditDetail";
import Account from "./Modal/Account";
import {getEnumEntries} from "../../functions/enum"

const Reports: React.FC = () => {
  const [searchName, setsearchName] = useState("");

  const [selectedRow, setselectedRow] = useState<dGroup>();

  const {
    loading,
    error,
    data = { getBackendDGroup: [] },
    refetch
  } = useQuery<{
    getBackendDGroup: dGroup[];
  }>(
    gql`
      query Query($query: String) {
        getBackendDGroup(query: $query) {
          name
          day
          time
          frequently
          category
          type
          location
          ccfId
          dgroupId
          members {
            type
            name
            phone
            location
            ccfId
            uid
          }
        }
      }
    `,
    {
      variables: {
        query: JSON.stringify(searchName ? {
          name: {
            $regex: searchName,
            $options: 'i'
          }
        } : {}),
      },
    }
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Category",
        Cell: ({ row, data }: { data: dGroup[]; row: any }) => {
          return dGroupCategoryEnum[parseInt(data[row.index].category)]
        },
      },
      {
        Header: "Type",
        Cell: ({ row, data }: { data: dGroup[]; row: any }) => {
          return dGroupTypeEnum[parseInt(data[row.index].type)].replace(
            "Dgroup",
            ""
          );
        },
      },
      {
        Header: "Location",
        Cell: ({ row, data }: { data: dGroup[]; row: any }) => {
          return dGroupLocationEnum[parseInt(data[row.index].location)]
        },
      },
      {
        Header: "Frequently",
        Cell: ({ row, data }: { data: dGroup[]; row: any }) => {
          return dGroupFrequncyEnum[parseInt(data[row.index].frequently)]
        },
      },
      {
        Header: "Day",
        Cell: ({ row, data }: { data: dGroup[]; row: any }) => {
          switch (dGroupFrequncyEnum[parseInt(data[row.index].frequently)]) {
            case "Weekly":
              return moment(data[row.index].day, 'ddd, MMM DD').format("ddd")
            case "Monthly":
              return moment(data[row.index].day, 'ddd, MMM DD').format("DD")
            case "Every 2 Weeks":
              return moment(data[row.index].day, 'ddd, MMM DD').format("ddd")
            default:
              return "";
          }
        },
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: " ",
        Cell: ({ row, data }: { data: dGroup[]; row: any }) => {
          return (
            <IonButton
              size="small"
              onClick={() => {
                pressent();
                setselectedRow(data[row.index]);
              }}
            >
              View
            </IonButton>
          );
        },
      },
    ],
    []
  );

  const tabs = React.useMemo(
    () => [
      {
        label: "Edit",
        icon: pencil,
        component: <EditDetail data={selectedRow!} />,
      }
    ],
    [selectedRow]
  );

  const closeModal = () => {
    dismiss();
    refetch()
    setselectedRow(undefined);
  };

  const [pressent, dismiss] = useIonModal(ModalTab, {
    data: selectedRow,
    dismiss: closeModal,
    tabs,
    onDismiss: closeModal,
    ionModalDidDismiss: closeModal,
    didDismiss: closeModal,
  });

  return (
    <IonPage>
      <Header />

      <ContentWrapper menu="app-menu">
        <IonCard
          style={{
            marginBottom: 16,
          }}
        >
          <IonRow>
            <IonCol size="3">
              <IonItem>
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput
                  value={searchName}
                  onIonChange={(ev) => {
                    if (ev.detail.value) {
                      setsearchName(ev.detail.value);
                    } else {
                      setsearchName("");
                    }
                  }}
                ></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonCard>

        <Table
          columns={columns}
          data={data.getBackendDGroup}
          loading={loading}
          errorGQL={error}
        />
      </ContentWrapper>
    </IonPage>
  );
};

interface ReportModalProps {
  selectedRow: dgroupReportQL;
  dismiss: () => void;
}

type tabModals = {
  label: string;
  icon: string;
  component: JSX.Element;
};
interface TabModal {
  children?: React.ReactNode;
  tab: tabModals[];
}

const TabModal: React.FC<TabModal> = ({ children, tab }) => {
  const [selected, setselected] = useState(tab[0].label);

  return (
    <>
      <TabPicker tabs={tab} selectedTab={selected} />

      <IonFooter>
        <IonSegment
          value={selected}
          onIonChange={(e) => {
            if (e.detail.value) setselected(e.detail.value);
          }}
        >
          {tab.map(({ icon, label }) => (
            <IonSegmentButton
              key={label}
              color="secondary"
              value={label}
              defaultChecked
              style={{
                paddingTop: 5,
              }}
            >
              <IonLabel color="secondary">{label}</IonLabel>
              <IonIcon color="secondary" src={icon} />
            </IonSegmentButton>
          ))}
        </IonSegment>
      </IonFooter>
    </>
  );
};

interface TabPickerProps {
  tabs: tabModals[];
  selectedTab: string;
}

const TabPicker: React.FC<TabPickerProps> = ({ tabs, selectedTab }) => {
  const index = _.findIndex(tabs, function (o) {
    return o.label === selectedTab;
  });
  switch (index) {
    case 0:
      return tabs[index].component;
    case 1:
      return tabs[index].component;
    case 2:
      return tabs[index].component;
    case 3:
      return tabs[index].component;
    case 4:
      return tabs[index].component;
    case 5:
      return tabs[index].component;
    default:
      return <></>;
  }
};

export default Reports;