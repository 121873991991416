import React, { useContext } from "react";
import {
  IonMenu,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonList,
  IonItem,
  IonAvatar,
  IonText,
  IonIcon,
  IonMenuToggle,
  IonChip,
  IonLabel,
} from "@ionic/react";
import { getAuth } from "firebase/auth";
import { UserContext } from "../../context/user.context";

import "./Menu.css";
import {
  home,
  logOut,
  person,
} from "ionicons/icons";
import _ from "lodash";
import packageJson from "../../../package.json"

const Menu: React.FC = () => {
  const auth = getAuth();
  const { userDoc } = useContext(UserContext);
  const { data: user } = userDoc;

  const navData = [
    {
      name: "Home",
      icon: home,
      link: "/",
    },
    {
      name: "Reports",
      icon: person,
      link: "/reports",
    },
  ];


  return (
    <IonMenu side="start" menuId="app-menu" contentId="app-menu">
      <IonHeader>
        <IonToolbar>
          <div style={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 5,
            fontSize: 11
          }}>v{packageJson.version}</div>
          <IonTitle>Menu</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="menu-user-avatar-div">
          <ProfileAvatar />

          {user && (
            <div className="ion-text-center user-displayName-div">
              <IonText className="user-displayName" color="secondary">
                {user?.displayName || "Display Name"}
              </IonText>
              
                <div>
                  <IonText color="secondary">{user.username}</IonText>
                </div>
            </div>
          )}

          {!_.get(user, 'accepted', false) && (
            <IonChip
              color="danger"
              style={{
                marginTop: 20,
              }}
            >
              <IonLabel>Your account needs to be Accepted.</IonLabel>
            </IonChip>
          )}
        </div>

        <IonList className="menu-list">
          {_.get(user, 'accepted', false)
            && navData.map((nav) => (
                <IonMenuToggle menu="app-menu" key={nav.name}>
                  <IonItem className="menu-item" routerLink={nav.link}>
                    <IonIcon src={nav.icon} />
                    {nav.name}
                  </IonItem>
                </IonMenuToggle>
              ))
            }
        </IonList>

        <div className="menu-item-logout">
          <IonItem className="menu-item" onClick={() => auth.signOut()}>
            <IonIcon src={logOut} />
            Log Out
          </IonItem>
        </div>
      </IonContent>
    </IonMenu>
  );
};

const ProfileAvatar: React.FC = () => {
  const { profileImg } = useContext(UserContext);

  return (
    <IonAvatar className="menu-user-avatar">
      <img src={profileImg} alt="user-pic" />
    </IonAvatar>
  );
};

export default Menu;
