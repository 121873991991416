import { getDownloadURL, getStorage, ref } from "firebase/storage";
import profileDefault from "../img/default-profile.jpg"

const getProfile = (uid: string): Promise<string> => new Promise((res) => {

    const storage = getStorage();
    const smallName = 'profile_200x200.png'
    const profileRefSmall = ref(storage, `${uid}/small/${smallName}`);
    
    getDownloadURL(profileRefSmall)
    .then((data) => {
      res(data)
    })
    .catch(() => {
        const profileRef = ref(storage, `${uid}/profile.png`);
        getDownloadURL(profileRef)
        .then((data) => {
            res(data)
        })
        .catch(() => {
           res(profileDefault)
        })
    })
})

export default getProfile