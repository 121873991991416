import {
  IonButton,
  IonCard,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useMemo } from "react";
import ContentWrapper from "../../../components/ContentWrapper";
import UserInfo from "../../../types/user.types";
import _ from "lodash";
import FormInput from "../../../components/Input/FormInput";
import { useForm } from "react-hook-form";
import { useFirestore } from "reactfire";
import { doc, updateDoc } from "@firebase/firestore";

interface UserInfoC extends UserInfo {
  createdAt: string;
}

interface ViewProfileProps {
  data: UserInfoC;
}

type formFields = {
    name: string;
    label?: string;
    accessor: string;
    type?: 'text' | 'date' | 'tel';
}

const EditDetail: React.FC<ViewProfileProps> = ({ data: profileUser }) => {
  const { control, handleSubmit } = useForm();

  const [presentL, dismissL] = useIonLoading()
  const [presentT] = useIonToast()

  

  const formFields = useMemo<formFields[]>(
    () => [
      {
        name: "firstName",
        label: "First Name",
        accessor: "firstName",
      },
      {
        name: "lastName",
        label: "Last Name",
        accessor: "lastName",
      },
      {
        name: "birthday",
        label: "Birthday",
        accessor: "birthday",
        type: "date"
      },
      {
        name: "phone",
        label: "Phone",
        accessor: "phone",
        type: 'tel'
      },
      {
        name: "postalCode",
        label: "Postal Code",
        accessor: "postalCode",
      },
      {
        name: "prefecture",
        label: "Prefecture",
        accessor: "prefecture",
      },
      {
        name: "city",
        label: "City",
        accessor: "city",
      },
      {
        name: "town",
        label: "Town",
        accessor: "town",
      },
    ],
    [profileUser]
  );

  const firestore = useFirestore();
  const userRef = doc(firestore, "users", `${_.get(profileUser, 'uid', '')}`);

   if(!profileUser) {
    return <></>
  }


  const registerUser = (data: Object) => {
    presentL()
    const d = Object.entries(data).map((d) => {
      if (d[1]) {
        return d
      }
    })
    .filter(Boolean) as [string, string][]

    const parseD = Object.fromEntries(d)


    if (_.get(parseD, 'firstName', "") && _.get(parseD, 'lastName', "")) {
      processSave({
        displayName: `${_.get(parseD, 'firstName', '')} ${_.get(parseD, 'lastName', '')}`,
        ...parseD
      })
    } else if (_.get(parseD, 'lastName', "")) {
      processSave({
        displayName: `${_.get(profileUser, 'firstName', '')} ${_.get(parseD, 'lastName', '')}`,
        ...parseD
      })
    } else if (_.get(parseD, 'lastName', "")) {
      processSave({
        displayName: `${_.get(profileUser, 'firstName', '')} ${_.get(parseD, 'lastName', '')}`,
        ...parseD
      })
    } else {
      processSave(parseD)
    }
    
  };

  const processSave = (toSave: {
    [k: string]: string;
}) => {
    updateDoc(userRef, toSave)
      .then((d) => {
        setTimeout(() => {
          dismissL()
          presentT({
            message: "Save Successfully.",
            duration: 700
          })
        }, 500)
      })
      .catch((d) => setTimeout(() => {
          dismissL()
          presentT({
            message: "Fail to save.",
            duration: 700,
            color: 'danger'
          })
        }, 500))
  }


  return (
    <ContentWrapper menu="app-menu">

    

      <form onSubmit={handleSubmit(registerUser)}>
        <IonCard
          style={{
            marginTop: 16,
          }}
        >

          {formFields.map((field, index) => (
            <FormInput
              {...field}
              control={control}
              key={index}
              value={_.get(profileUser, field.accessor, "")}
            />
          ))}
        </IonCard>
        <div style={{
          margin: '0 16px'
        }}>
          <IonButton expand="block" type="submit">Change</IonButton>
        </div>
      </form>

      
    </ContentWrapper>
  );
};

export default EditDetail;
