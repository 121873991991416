import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTab,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonText,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import ContentWrapper from "../../components/ContentWrapper";
import Header from "../../components/Header";
import Table from "../../components/Table/Table";
import DateRangeInput from "../../components/Input/DateRangeInput";
import { gql, useQuery } from "@apollo/client";
import dGroup, { dGroupReport, dgroupReportQL } from "../../types/dgroup.types";
import moment from "moment";
import {parsePhoneNum} from "../../functions/parsePhoneNum"

import {
  build,
  calendar,
  closeSharp,
  documentText,
  home,
  images,
  informationCircle,
  map,
  pencil,
  people,
  person,
  personCircle,
} from "ionicons/icons";
import _ from "lodash";
import { StateContext } from "../../context/state.context";
import UserInfo from "../../types/user.types";
import ModalTab from "../../components/Modal/ModalTab"
import ViewProfile from "./Modal/Info"
import EditDetail from "./Modal/EditDetail"
import Account from "./Modal/Account"

interface UserInfoC extends UserInfo {
  createdAt: string
}

const Reports: React.FC = () => {

  const [searchName, setsearchName] = useState("")

  const [selectedReportRow, setselectedReportRow] = useState<UserInfoC>()
 
  const {
    loading,
    error,
    data = { getBackendMemberSearch: [] },
    refetch
  } = useQuery<{
    getBackendMemberSearch: UserInfoC[];
  }>(
    gql`
      query Query($name: String) {
        getBackendMemberSearch(name: $name) {
          uid
          firstName
          birthday
          lastName
          displayName
          ccf_location
          phone
          accepted
          postalCode
          prefecture
          town
          city
          addressLine
          username
          pin
          createdAt
          dGroupLeader
        }
      }
    `,
    {
      variables: {
        name: searchName
      },
    }
  );

  
  const closeModal = () => {
    dismiss();
    refetch();
    //setselectedReportRow(undefined);
  };

  const tabs = React.useMemo(
    () => [
      {
        label: "Info",
        icon: person,
        component: <ViewProfile data={selectedReportRow!} />,
      },
      {
        label: "Edit",
        icon: pencil,
        component: <EditDetail data={selectedReportRow!} />,
      },
      {
        label: "Account",
        icon: build,
        component: <Account data={selectedReportRow!} {...{dismiss: closeModal}}/>,
      }
    ],
    [selectedReportRow]
  );

  const [pressent, dismiss] = useIonModal(ModalTab, {
    data: selectedReportRow,
    dismiss: closeModal,
    tabs
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "displayName",
      },
      {
        Header: "Phone",
        Cell: ({ row, data }: { data: UserInfoC[]; row: any }) => {
          return <a href={`tel:${data[row.index].phone}`}>{parsePhoneNum(data[row.index].phone)}</a>
        },
      },
      {
        Header: "Location",
        accessor: "ccf_location",
      },
      {
        Header: "Birthday",
        accessor: "birthday"
      },
      {
        Header: "Join Date",
        Cell: ({ row, data }: { data: UserInfoC[]; row: any }) => {
          return moment(data[row.index].createdAt, "x").format("LL");
        },
      },
      {
        Header: " ",
        Cell: ({ row, data }: { data: UserInfoC[]; row: any }) => {
          return (
            <IonButton
              size="small"
              onClick={() => {
               pressent()
               setselectedReportRow(data[row.index])
              }}
            >
              View
            </IonButton>
          );
        },
      },
    ],
    []
  );

  


  return (
    <IonPage>
      <Header />

      <ContentWrapper menu="app-menu">
        <IonCard
          style={{
            marginBottom: 16,
          }}
        >
          <IonRow>
            <IonCol size="3">
              <IonItem>
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput value={searchName} onIonChange={(ev) => {
                  if (ev.detail.value) {
                    setsearchName(ev.detail.value)
                  } else {
                    setsearchName("")
                  }
                }}></IonInput>
              </IonItem>
            </IonCol>
          </IonRow>
        </IonCard>

        <Table
          columns={columns}
          data={data.getBackendMemberSearch}
          loading={loading}
          errorGQL={error}
        />
      </ContentWrapper>
    </IonPage>
  );
};

interface ReportModalProps {
  selectedRow: dgroupReportQL;
  dismiss: () => void;
}


type tabModals = {
  label: string;
  icon: string;
  component: JSX.Element;
};
interface TabModal {
  children?: React.ReactNode;
  tab: tabModals[];
}

const TabModal: React.FC<TabModal> = ({ children, tab }) => {
  const [selected, setselected] = useState(tab[0].label);

  return (
    <>
      
        <TabPicker tabs={tab} selectedTab={selected}/>
  
      <IonFooter>
        <IonSegment
          value={selected}
          onIonChange={(e) => {
            if (e.detail.value) setselected(e.detail.value);
          }}
        >
          {tab.map(({ icon, label }) => (
            <IonSegmentButton
              key={label}
              color="secondary"
              value={label}
              defaultChecked
              style={{
                paddingTop: 5,
              }}
            >
              <IonLabel color="secondary">{label}</IonLabel>
              <IonIcon color="secondary" src={icon} />
            </IonSegmentButton>
          ))}
        </IonSegment>
      </IonFooter>
    </>
  );
};

interface TabPickerProps {
  tabs: tabModals[];
  selectedTab: string;
}

const TabPicker: React.FC<TabPickerProps> = ({ tabs, selectedTab }) => {
  const index = _.findIndex(tabs, function (o) {
    return o.label === selectedTab;
  });
  switch (index) {
    case 0:
      return tabs[index].component;
    case 1:
      return tabs[index].component;
    case 2:
      return tabs[index].component;
    case 3:
      return tabs[index].component;
    case 4:
      return tabs[index].component;
    case 5:
      return tabs[index].component;
    default:
      return <></>;
  }
};

export default Reports;
