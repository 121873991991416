import {
  IonAvatar,
  IonCard,
  IonIcon,
  IonItem,
  IonPage,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  getDownloadURL,
  getStorage,
  ref,
} from "firebase/storage";
import { useEffect, useState } from "react";
import ContentWrapper from "../../../components/ContentWrapper";
import UserInfo from "../../../types/user.types";
import defaultProfileImg from "../../../img/default-profile.jpg";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import {
  call,
  gift,
  home,
  navigate,
  person,
} from "ionicons/icons";
import getProfile from "../../../functions/getPrrofile"

interface UserInfoC extends UserInfo {
  createdAt: string
}


interface ViewProfileProps {
  data: UserInfoC
}

const ViewProfile: React.FC<ViewProfileProps> = ({data: profileUser}) => {

  if (!profileUser) {
    return <></>
  }

  return (
      <ContentWrapper menu="app-menu">
        <ProfileAvatar uid={profileUser.uid!} />
        <div
          className="ion-text-center"
          style={{
            paddingTop: 10,
          }}
        >
          <IonText className="user-displayName ">
            {profileUser.displayName}
          </IonText>
        </div>

        <IonCard
          style={{
            marginTop: 16,
          }}
        >
          <IonText>
            <h6
              style={{
                padding: "0 16px",
              }}
            >
              Info
            </h6>
          </IonText>

          <IonItem
            style={{
              marginRight: 24,
            }}
          >
            <IonIcon slot="start" icon={person} size="small" />
            <IonText
              color="secondary"
              style={{
                fontSize: 12,
              }}
            >
              {profileUser.username}
            </IonText>
          </IonItem>

          <IonItem
            style={{
              marginRight: 24,
            }}
          >
            <IonIcon slot="start" icon={navigate} size="small" />
            <IonText
              color="secondary"
              style={{
                fontSize: 12,
              }}
            >
              {profileUser.ccf_location}
            </IonText>
          </IonItem>

          <IonItem
            style={{
              marginRight: 24,
            }}
          >
            <IonIcon slot="start" icon={gift} size="small" />
            <IonText
              color="secondary"
              style={{
                fontSize: 12,
              }}
            >
              {profileUser.birthday}
            </IonText>
          </IonItem>

          <IonItem
            style={{
              marginRight: 24,
            }}
          >
            <IonIcon slot="start" icon={call} size="small" />
            <IonText
              style={{
                fontSize: 12,
              }}
            >
              <a href={`tel:${profileUser.phone}`}>{profileUser.phone}</a>
            </IonText>
          </IonItem>

          <IonItem
            style={{
              marginRight: 24,
            }}
          >
            <IonIcon slot="start" icon={home} size="small" />
            <IonText
              color="secondary"
              style={{
                fontSize: 12,
              }}
            >
              {`${profileUser.postalCode} ${profileUser.prefecture} ${profileUser.city} ${profileUser.town} ${profileUser.addressLine}`}
            </IonText>
          </IonItem>
        </IonCard>

        {profileUser.displayName !== 'Joey Misa' && profileUser.dGroupLeaderProfile && (
          <IonCard
            style={{
              marginTop: 5,
            }}
          >
            <IonText>
              <h6
                style={{
                  padding: "0 16px",
                }}
              >
                Dgroup Leader
              </h6>
            </IonText>

            <IonItem
              style={{
                marginRight: 24,
              }}
            >
              <IonIcon slot="start" icon={person} size="small" />
              <IonText
                color="secondary"
                style={{
                  fontSize: 12,
                }}
              >
                {profileUser.dGroupLeaderProfile.displayName}
              </IonText>
            </IonItem>

            <IonItem
              style={{
                marginRight: 24,
              }}
            >
              <IonIcon slot="start" icon={navigate} size="small" />
              <IonText
                color="secondary"
                style={{
                  fontSize: 12,
                }}
              >
                {profileUser.dGroupLeaderProfile.ccf_location}
              </IonText>
            </IonItem>

            <IonItem
              style={{
                marginRight: 24,
              }}
            >
              <IonIcon slot="start" icon={gift} size="small" />
              <IonText
                color="secondary"
                style={{
                  fontSize: 12,
                }}
              >
                {profileUser.dGroupLeaderProfile.birthday}
              </IonText>
            </IonItem>

            <IonItem
              style={{
                marginRight: 24,
              }}
            >
              <IonIcon slot="start" icon={call} size="small" />
              <IonText
                style={{
                  fontSize: 12,
                }}
              >
                <a href={`tel:${profileUser.dGroupLeaderProfile.phone}`}>
                  {profileUser.dGroupLeaderProfile.phone}
                </a>
              </IonText>
            </IonItem>

            <IonItem
              style={{
                marginRight: 24,
              }}
            >
              <IonIcon slot="start" icon={home} size="small" />
              <IonText
                color="secondary"
                style={{
                  fontSize: 12,
                }}
              >
                {`${profileUser.dGroupLeaderProfile.postalCode} ${profileUser.dGroupLeaderProfile.prefecture} ${profileUser.dGroupLeaderProfile.city} ${profileUser.dGroupLeaderProfile.town} ${profileUser.dGroupLeaderProfile.addressLine}`}
              </IonText>
            </IonItem>
          </IonCard>
        )}
      </ContentWrapper>
  );
};

export default ViewProfile;

interface ProfileAvatarProps {
  uid: string;
}

const ProfileAvatar: React.FC<ProfileAvatarProps> = ({uid}) => {

  const [profile, setprofile] = useState(defaultProfileImg)

  
  const storage = getStorage();
  const profileRef = ref(storage, `${uid}/profile.png`);

  useEffect(() => {
    getProfile(uid).then((data) => {
      setprofile(data);
    });
  }, [])

  return (
    <div className="menu-user-avatar-div">
      <IonAvatar className="menu-user-avatar">
        <img src={profile} alt="user-pic" />
      </IonAvatar>
    </div>
  );
};
