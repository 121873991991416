import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { colorFilter, fileTrayFull, home, people, square } from "ionicons/icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import {
  useFirebaseApp,
  useInitPerformance,
  FirestoreProvider,
  FunctionsProvider,
  AuthProvider,
  StorageProvider,
  SuspenseWithPerf,
  useSigninCheck,
} from "reactfire";

import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./pages/Error";
import SplashScreen from "./pages/SplashScreen";
import React from "react";
import Auth from "./Auth";
import routes from "./routes";
import { UserContextProvider } from "./context/user.context";
import Menu from "./components/Menu/Menu";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { StateContextProvider } from "./context/state.context";

setupIonicReact({
  mode: "ios",
  spinner: "crescent",
});

const client = new ApolloClient({
  uri:
    process.env.NODE_ENV === "development"
      ? "http://10.10.10.133:5001/ccf-app-f98f4/asia-northeast1/graphql"
      : "https://asia-northeast1-ccf-app-f98f4.cloudfunctions.net/graphql",
  cache: new InMemoryCache({
    addTypename: false
  }),
});

const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const firebaseApp = useFirebaseApp();
  const auth = getAuth(firebaseApp);
  const storage = getStorage(firebaseApp);
  const func = getFunctions(firebaseApp, "asia-northeast1");

  const firestoreInstance = getFirestore(firebaseApp);

  if (process.env.NODE_ENV === "development") {
    //connectFirestoreEmulator(firestoreInstance, "10.10.10.133", 8080);
    //connectFunctionsEmulator(func, "10.10.10.133", 5001);
    //connectAuthEmulator(auth, "http://10.10.10.133:9099");
    //connectStorageEmulator(storage, "10.10.10.133", 9199);
  }

  useInitPerformance(
    async (firebaseApp) => {
      const { getPerformance } = await import("firebase/performance");
      return getPerformance(firebaseApp);
    },
    { suspense: false } // false because we don't want to stop render while we wait for perf
  );

  return (
    <ApolloProvider client={client}>
      <FirestoreProvider sdk={firestoreInstance}>
        <FunctionsProvider sdk={func}>
          <AuthProvider sdk={auth}>
            <StorageProvider sdk={storage}>
              <ErrorBoundary
                FallbackComponent={Error}
                onReset={() => {
                  ///if (auth.currentUser) auth.signOut()
                  window.location.assign(window.location.hostname);
                }}
              >
                {children}
              </ErrorBoundary>
            </StorageProvider>
          </AuthProvider>
        </FunctionsProvider>
      </FirestoreProvider>
    </ApolloProvider>
  );
};

const AppAuth = ({
  children,
  fallback,
}: React.PropsWithChildren<{ fallback: JSX.Element }>): JSX.Element => {
  const { data: signInCheckResult } = useSigninCheck();

  if (!children) {
    // throw Error("Children must be provided")
  }

  if (signInCheckResult.signedIn === true) {
    return children as JSX.Element;
  } else {
    return fallback;
  }
};

const App: React.FC = () => {
  const getRoutes = () =>
    routes.map((route, key) => {
      const Component = route.element;

      if (!route.isRedirect) {
        return (
          <Route
            exact={!!route.isExact}
            path={route.path}
            key={key}
            component={Component}
          />
        );
      } else if (route.isRedirect) {
        return (
          <Route exact={!!route.isExact} key={key}>
            <Redirect to={route.toRedirect} />
          </Route>
        );
      } else {
        return (
          <Route
            exact={!!route.isExact}
            path={route.path}
            key={key}
            component={route.element}
          />
        );
      }
    });

  return (
    <AppProvider>
      <SuspenseWithPerf
        traceId={"firebase-user-wait"}
        fallback={<SplashScreen />}
      >
        <AppAuth fallback={<Auth />}>
          <UserContextProvider>
            <StateContextProvider>
              <IonApp>
                <IonReactRouter>
                  <Menu />
                  <IonTabs>
                    <IonRouterOutlet>{getRoutes()}</IonRouterOutlet>
                    <IonTabBar slot="bottom">
                      <IonTabButton tab="tab1" href="/home">
                        <IonIcon icon={home} />
                        <IonLabel>Home</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab2" href="/reports">
                        <IonIcon icon={fileTrayFull} />
                        <IonLabel>Report</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab3" href="/members">
                        <IonIcon icon={people} />
                        <IonLabel>Members</IonLabel>
                      </IonTabButton>
                      <IonTabButton tab="tab4" href="/dgroups">
                        <IonIcon icon={colorFilter} />
                        <IonLabel>DGroups</IonLabel>
                      </IonTabButton>
                    </IonTabBar>
                  </IonTabs>
                </IonReactRouter>
              </IonApp>
            </StateContextProvider>
          </UserContextProvider>
        </AppAuth>
      </SuspenseWithPerf>
    </AppProvider>
  );
};

export default App;
