import { IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import _ from "lodash";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { getEnumEntries } from "../../functions/enum";

export interface InputProps {
  name: string;
  control?: Control;
  label?: string;
  component?: JSX.Element;
  value?: string
  type?: "text" | 'date' | 'tel'
  select: {[index: string]: string | number}
}

const FormSelect: FC<InputProps> = ({ name, control, component, label, value: valueV, type = "text", select }) => {
  return (
    <>
      <IonItem>
        {label && <IonLabel position="floating">{label}</IonLabel>}
        <Controller
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
           
            <IonSelect
              ref={ref}

              interface="popover"
              placeholder="Select One"
              onIonChange={onChange}
              value={_.get({value}, 'value', parseInt(valueV!))}>
                {select && getEnumEntries(select).map(((s, key) => <IonSelectOption key={key} value={s[1]}>{s[0]}</IonSelectOption>))}
            </IonSelect>
          )}
          name={name}
          control={control}
        />
      </IonItem>
    </>
  );
};

export default FormSelect;
