import { ApolloError } from "@apollo/client";
import { IonCard, IonCardContent, IonChip, IonCol, IonGrid, IonLabel, IonRow, IonSpinner } from "@ionic/react";
import { useTable, usePagination, Column } from "react-table";
import Pagination from "./Pagination";
import "./Table.css"

type Col = {
  Header: string;
  accessor?: string;
  id?: string
  Cell: () => any
};

interface TableProps {
  columns: any[];
  data: any[];
  loading?: boolean
  errorGQL?: ApolloError
}

const Table: React.FC<TableProps> = ({ columns, data, loading, errorGQL }) => {
  const { 
    getTableProps, getTableBodyProps, headerGroups, prepareRow, state, 
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
   } = useTable({ columns, data, initialState: { pageIndex: 0 }, }, usePagination);

  return (
    <IonCard style={{
      marginTop: 16
    }}>
      <IonCardContent>
      <IonGrid {...getTableProps()} 
      >
          {headerGroups.map((headerGroup) => (
            <IonRow {...headerGroup.getHeaderGroupProps()} className='tableHeader'>
              {headerGroup.headers.map((column) => (
                <IonCol
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </IonCol>
              ))}
            </IonRow>
          ))}

          {loading && <div className="center"><IonSpinner></IonSpinner></div>}

          {errorGQL && <IonRow>
            <IonCol>
              <IonChip>
                {errorGQL.message && <IonLabel>{JSON.stringify(errorGQL.message)}</IonLabel>}
              </IonChip>
              <IonChip>
                {errorGQL.clientErrors && <IonLabel>{JSON.stringify(errorGQL.clientErrors)}</IonLabel>}
              </IonChip>
            </IonCol>
            </IonRow>}

          {!loading && data && <div {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <IonRow {...row.getRowProps()} className='tableRow'>
                {row.cells.map((cell) => {
                  return (
                    <IonCol className='tableCell'
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </IonCol>
                  );
                })}
              </IonRow>
            );
          })}
        </div>}
      </IonGrid>
      </IonCardContent>

      <Pagination PageFunction={{
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state
      }}/>
    </IonCard>
  );
};

export default Table;