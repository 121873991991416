import Home from "./pages/Home"
import Reports from "./pages/Reports"
import Members from "./pages/Members"
import DGroups from "./pages/DGroups"

const routes = [
    {
        path: "*", isRedirect: true, toRedirect: '/home'
    },
    {
        name: "home", path: "/home", isExact: true, element: Home
    },
    {
        name: 'reports', path: "/reports", isExact: true, element: Reports
    },
    {
        name: 'members', path: "/members", isExact: true, element: Members
    },
    {
        name: 'dgroups', path: "/dgroups", isExact: true, element: DGroups
    },
]

export default routes