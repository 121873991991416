import {
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPopover,
  useIonPopover,
  IonText,
} from "@ionic/react";
import { bookmark, navigate } from "ionicons/icons";
import ContentWrapper from "../../components/ContentWrapper";
import InputModal from "./Modal/InputModal";
import ItemModal from "./Modal/ItemModal";
import { dGroupReport, dgroupReportQL } from "../../types/dgroup.types";
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../context/state.context";
import _ from "lodash";
import SelectModal from "./Modal/SelectModal";
import TextAreaModal from "./Modal/TextAreaModal";
import { gql, useQuery } from "@apollo/client";
import bible, { bibleMeta } from "../../types/bible.types";
import ReactMarkdown from "react-markdown";

interface InfoModalProps {
  //reportData: dgroupReportQL;
}

const igetVerse = (v: string) => {
  if (v) {
    const v1 = v.split(" ");
    if (v1.length === 2) {
      const book = v.split(" ")[0];
      const cv = v.split(" ")[1].split(":");

      const chapter = parseInt(cv[0]);
      const verse = cv[1];

      return {
        book,
        chapter,
        verse,
      };
    } else if (v1.length === 3) {
      const book = `${v1[0]} ${v1[1]}`;
      const cv = v1[2].split(":");

      const chapter = parseInt(cv[0]);
      const verse = cv[1];

      return {
        book,
        chapter,
        verse,
      };
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

const InfoModal: React.FC<InfoModalProps> = () => {
  const { selectedReportRow, setselectedReportRow, bibleMeta } =
    useContext(StateContext);
  const [translation, settranslations] = useState(localStorage.getItem('translation') || "WEB");

  const settranslation = (t: string) => {
    settranslations(t)
    localStorage.setItem('translation', t)
  }

  const {
    loading,
    error,
    data = { getVerse: [] },
  } = useQuery<{
    getVerse: bible[];
  }>(
    gql`
      query Query($query: bibleQuery) {
        getVerse(query: $query) {
          book_name
          chapter
          verse
          text
        }
      }
    `,
    {
      variables: {
        query: {
          type: translation,
          ...igetVerse(_.get(selectedReportRow, "verse", "")),
        },
      },
    }
  );

  const [present, dismiss] = useIonPopover(BibleList, { bibleMeta, settranslation });

  return (
    <ContentWrapper>
      <IonList>
        <IonListHeader>Info</IonListHeader>
        <InputModal
          label="Title"
          path={"title"}
          data={selectedReportRow!}
          setState={setselectedReportRow}
        />
        {/* <InputModal
          label="Book"
          path={"book.name"}
          data={selectedReportRow!}
          setState={setselectedReportRow}
        />
        <InputModal
          label="Chapter"
          path={"chapter.name"}
          data={selectedReportRow!}
          setState={setselectedReportRow}
        /> */}

        <InputModal
          label="Verse"
          path={"verse"}
          data={selectedReportRow!}
          setState={setselectedReportRow}
        />

        <IonItem>
          <IonLabel
            style={{
              fontWeight: "bold",
              marginRight: 15,
            }}
          >
            Verse:
          </IonLabel>
          <IonInput
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            value={_.get(selectedReportRow!, "verse", "")}
            onIonChange={(ev) => {
              if (ev.detail.value) {
                setselectedReportRow(
                  _.setWith(
                    { ...selectedReportRow! },
                    "verse",
                    ev.detail.value,
                    Object
                  )
                );
              } else {
                setselectedReportRow(
                  _.setWith({ ...selectedReportRow! }, "verse", "", Object)
                );
              }
            }}
          />
          <IonButton slot="end" id="trigger-button" color="dark" onClick={(e) => present({
            dismissOnSelect: true,
            event: e.nativeEvent
          })}>
            {translation}
          </IonButton>
          
        </IonItem>
        {data && data.getVerse && data.getVerse.length > 0 && (
          <IonText>
            <div
              style={{
                marginLeft: 20,
                marginRight: 20,
                textAlign: "justify",
              }}
            >
              <ReactMarkdown>{gVerse(data.getVerse)}</ReactMarkdown>
            </div>
          </IonText>
        )}

        <TextAreaModal
          label="Note"
          path={"note"}
          data={selectedReportRow!}
          setState={setselectedReportRow}
          rows={4}
          cols={10}
        />
      </IonList>
    </ContentWrapper>
  );
};


const BibleList: React.FC<{
  bibleMeta: bibleMeta[],
  settranslation: React.Dispatch<React.SetStateAction<string>>

}> = ({bibleMeta, settranslation}) => {

  return <IonList>
  {[...bibleMeta].sort((a,b) => (a.lang > b.lang) ? 1 : ((b.lang > a.lang) ? -1 : 0)).map((b) => (
    <IonItem button detail datatype={"b"} defaultValue="b" onClick={() => settranslation(b.shortname)} key={b.name}>
      <div>
        <IonLabel>{b.shortname}</IonLabel>
        <IonLabel
          style={{
            fontSize: 7,
          }}
        >
          {b.name}
        </IonLabel>
      </div>
      <IonText style={{ fontSize: 10 }} slot="end">
        ({b.lang})
      </IonText>
      <IonNote slot="error">Helper Text</IonNote>
    </IonItem>
  ))}
</IonList>
}

const gVerse = (v: bible[]) => {
  let verse = "";
  let verseA: string[] = [];

  v.forEach((v) => {
    if (verse === "") {
      verse = verse + `**${v.verse}** ${v.text}`;
    } else {
      verse = verse + ` **${v.verse}** ${v.text}`;
    }
    verseA.push(`${v.verse} ${v.text}`);
  });

  return verse;
};

export default InfoModal;
