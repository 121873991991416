import { gql, useQuery } from "@apollo/client";
import { ReactComponentOrElement } from "@ionic/react";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React, { createContext, ReactNode, useState } from "react";
import { bibleMeta } from "../types/bible.types";
import { dgroupReportQL } from "../types/dgroup.types";
import UserInfo from '../types/user.types'


interface StateContextInterface {
  selectedReportRow?: dgroupReportQL,
  setselectedReportRow: React.Dispatch<React.SetStateAction<dgroupReportQL | undefined>>
  bibleMeta: bibleMeta[]
}

const StateContext = createContext({} as StateContextInterface);

const StateContextProvider: React.FC<{children: ReactNode}> = ({ children }): JSX.Element => {
    const [selectedReportRow, setselectedReportRow] = useState<dgroupReportQL>()

    const {
      loading,
      error,
      data: {getBibleMeta: bibleMeta = []} = { getBibleMeta: [] },
    } = useQuery<{
      getBibleMeta: bibleMeta[];
    }>(
      gql`
        query Query {
          getBibleMeta {
            name
            shortname
            lang
          }
        }
      `
    );

    
  return (
    <StateContext.Provider
      value={{
        selectedReportRow,
        setselectedReportRow,
        bibleMeta
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export { StateContext, StateContextProvider};
