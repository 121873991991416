import { gql, useQuery } from "@apollo/client";
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonIcon,
  IonText,
} from "@ionic/react";
import { archive, book, people, person } from "ionicons/icons";
import "./StatCard.css";

interface statData {
  members: number
  dgroups: number
  reports: number
}

const StatCard: React.FC = () => {

  const {data} = useQuery<{getStatData: statData}>(gql`query Query {
  getStatData {
    members
    dgroups
    reports
  }
}`)

  return (
    <IonRow style={{
      margin: '0 270px'
    }}>
      <IonCol>
        <Card label="Members" icon={people} count={data?.getStatData.members!} />
      </IonCol>
      <IonCol>
        <Card label="Dgroups" icon={book} count={data?.getStatData.dgroups!} />
      </IonCol>
      <IonCol>
        <Card label="Reports" icon={archive} count={data?.getStatData.reports!} />
      </IonCol>
    </IonRow>
  );
};

interface CardProps {
  count: number
  label: string
  icon: string
}

const Card: React.FC<CardProps> = ({count, label, icon}) => {
  return (
    <IonCard>
      <IonCardContent>
        <div className="statCardIcon">
          <div className="statCardIcon">
            <IonIcon
              src={icon}
              color='primary'
              style={{
                fontSize: "3em",
              }}
            />
          </div>

          <div className="ion-text-right">
            <IonText>{label}</IonText>
            <div>
              <IonText
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                }}
              >
                {count}
              </IonText>
            </div>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default StatCard;
