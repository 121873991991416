import {
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  useIonPopover,
} from "@ionic/react";
import { calendar } from "ionicons/icons";
import moment from "moment";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "./DateRangeInput.css";

interface DateRangeInputProps {
  state: [
    dateRange: date,
    setdateRange: React.Dispatch<React.SetStateAction<date>>
  ];
  label?: string;
}

const DateRangeInput: React.FC<DateRangeInputProps> = ({ label, state }) => {
  const [dateRange, setdateRange] = state;

  const [present, dismiss] = useIonPopover(Calendar, {
    dateRange,
    setdateRange,
    onHide: () => dismiss(),
  });

  return (
    <div
      className="dRangeDiv"
      style={{
        paddingLeft: 16,
      }}
    >
      <div slot="start" className="ion-text-center">
        <IonIcon
          src={calendar}
          style={{
            fontSize: 24,
          }}
        />
      </div>
      <IonItem lines="none">
        <IonLabel position="stacked">{label && label}</IonLabel>

        <IonInput
          value={`${moment(dateRange.startDate).format("MMM DD")} to ${moment(
            dateRange.endDate
          ).format("MMM DD")}`}
          onClick={(e) =>
            present({
              event: e.nativeEvent,
              size: "cover",
              id: "calendarPopup",
            })
          }
        ></IonInput>
      </IonItem>
    </div>
  );
};

export default DateRangeInput;

type date = {
  startDate: Date;
  endDate: Date;
  key: string;
  color: string;
};

interface CalendarProps {
  dateRange: date;
  setdateRange: React.Dispatch<React.SetStateAction<date>>;
}

const Calendar: React.FC<CalendarProps> = ({ dateRange, setdateRange }) => {
  return (
    <div className="ion-text-center">
      <DateRange
        editableDateInputs={true}
        ranges={[dateRange]}
        onChange={(e: any) => setdateRange(e.selection)}
        moveRangeOnFirstSelection={false}
        retainEndDateOnFirstSelection={false}
        color="#44ACAD"
      />
    </div>
  );
};
