import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from "reactfire";
import { defineCustomElements } from "@ionic/pwa-elements/loader";

const firebaseConfig = {
  apiKey: "AIzaSyCq9O6jIJnpuqmb0z1EBScUgpK81wvV9gE",
  authDomain: "ccf-app-f98f4.firebaseapp.com",
  projectId: "ccf-app-f98f4",
  storageBucket: "ccf-app-f98f4.appspot.com",
  messagingSenderId: "634927468482",
  appId: "1:634927468482:web:ba596e39e77904adcb505b",
};

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


//React 18
const root = ReactDOM.createRoot(document!.getElementById("root")!);
root.render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={true}>
      <App />
    </FirebaseAppProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

defineCustomElements(window);