import {
  IonSpinner
} from '@ionic/react'

const Loading: React.FC = () => {
  return (
    <div className="ion-text-center">
      <IonSpinner />
    </div>
  )
}

export default Loading
