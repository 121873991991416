import UserInfo from "./user.types"

export default interface dGroup {
  name: string
  day: string
  time: string
  frequently: string
  category: string
  type: string
  location: string
  ccfId: string
  dgroupId: string
  uid: string
  members: dGroupMember[]
}

export interface dGroupMember  {
  type: dGroupMemberType,
  name: string,
  ccfId: string,
  uid: string,
  phone: string,
  location: string
}


export interface dGroupReport {
  title: string
  verse: string
  note: string
  bibleVerse: string
  reportId: string
  dgroupId: string
  uid: string
  ccfId: string
  createdAt: string
}

export enum dGroupCategoryEnum {
  "ID (Accountability)",
  "Couples",
  "Family",
  "Single Parents",
  "Mens",
  "Womens",
  "Mixed",
  "Singles (BIG)",
  "Elevate",
  "NxtGen",
  "Others"
}

export enum dGroupFrequncyEnum {
  'Once',
  'Daily',
  'Weekly',
  'Every 2 Weeks',
  'Monthly'
}

export enum dGroupTypeEnum {
  'Online Dgroup',
  'Physical  Dgroup'
}

export enum dGroupLocationEnum {
  'Aichi',
  'Chiba',
  'Ibaraki',
  'Kanagawa',
  'Kyoto',
  'Mie',
  'Nagano',
  'Nagoya',
  'Okinawa',
  'Osaka',
  'Philippines',
  'Sagamihara',
  'Saitama',
  'Takamatsu',
  'Tokyo',
  'Yamato',
  'Yokohama',
  'Yokosuka',
  'Gunma'
}

export enum dGroupMemberType {
  Leader,
  Member,
  Temporary
}

export interface dgroupReportQL extends dGroupReport {
  dgroup: dGroup;
}