import {
  IonContent,
  IonPage,
} from "@ionic/react";
import ContentWrapper from "../components/ContentWrapper";
import Header from "../components/Header";
import StatCard from "../components/StatCard";
import "./Home.css";

const Home: React.FC = () => {
  return (
    <IonPage>
     
        <Header />

        <ContentWrapper menu="app-menu">

          <StatCard />
          

        </ContentWrapper>
     
    </IonPage>
  );
};

export default Home;
