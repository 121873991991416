export const parsePhoneNum = (v: string) => {

    switch (v.length) {
        case 10:
          //landline
          return `(${v.slice(0, 2)}) ${v.slice(2, 6)} ${v.slice(6)}`  
        case 11:
          //mobile
          return `(${v.slice(0, 3)}) ${v.slice(3, 7)} ${v.slice(7)}`  

        
        default:
           if (v) {
             return `(${v.slice(0, 3)}) ${v.slice(3, 7)} ${v.slice(7)}`
           } else {
             return ""
           }
    }
}