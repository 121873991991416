import {
  IonButton,
  IonHeader,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { FallbackProps } from 'react-error-boundary'
import ContentWrapper from '../components/ContentWrapper'


const Error: React.FC<FallbackProps> = ({error, resetErrorBoundary}) => {
  
  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className='header ion-no-border'>
          <IonTitle className='header-title'>CCF JAPAN</IonTitle>
        
        </IonToolbar>
      </IonHeader>
      <ContentWrapper menu="app-menu">
  
  
      <div>
          <div
            className='ion-text-center'
            style={{
              marginTop: 50
            }}
          >
            <IonText className='sucess-text ion-text-center' color='secondary'>Oopss!</IonText>
          </div>
          <div className='ion-text-center'>
            <IonText color='primary'>Something went wrong.</IonText>
            <div className='ion-text-center'>
            <IonText color='danger'>
              <small>
              {error.message}
              </small>
            </IonText>
          </div>
          </div>
          <div className='ion-text-center' style={{
              marginTop: 50
            }}>
          <IonButton onClick={resetErrorBoundary}> Reload </IonButton>
          </div>
         
         
        </div>
        
      </ContentWrapper>
    </IonPage>
  )
}

export default Error
