import {
  IonBadge,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTab,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import React, { useContext, useState } from "react";
import ContentWrapper from "../components/ContentWrapper";
import Header from "../components/Header";
import Table from "../components/Table/Table";
import DateRangeInput from "../components/Input/DateRangeInput";
import { gql, useQuery } from "@apollo/client";
import dGroup, { dGroupReport, dgroupReportQL } from "../types/dgroup.types";
import moment from "moment";

import "./Reports.css";
import {
  calendar,
  closeSharp,
  documentText,
  home,
  images,
  informationCircle,
  map,
  people,
  personCircle,
} from "ionicons/icons";
import _ from "lodash";
import InfoModal from "./Reports/InfoModalContent";
import { StateContext } from "../context/state.context";
import MemberModal from "./Reports/MemberModalContent";
import ImageModal from "./Reports/ImageModalContent";


const Reports: React.FC = () => {
  const [dateRange, setdateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    color: "#44ACAD",
  });

  const {selectedReportRow, setselectedReportRow} = useContext(StateContext)

  const {
    loading,
    error,
    data = { dgroupReport: [] },
  } = useQuery<{
    dgroupReport: dgroupReportQL[];
  }>(
    gql`
      query Query($startDate: Int, $endDate: Int) {
        dgroupReport(startDate: $startDate, endDate: $endDate) {
          title
          verse
          note
          bibleVerse
          createdAt
          dgroupId
          reportId
          members {
            name
            uid
            type
          }
          dgroup {
            name
            day
            time
            frequently
            category
            type
            location
            members {
              name
              uid
              type
            }
          }
        }
      }
    `,
    {
      variables: {
        startDate: moment(dateRange.startDate).unix(),
        endDate: moment(dateRange.endDate).unix(),
      },
    }
  );

  const closeModal = () => {
    dismiss();
    setselectedReportRow(undefined);
  };

  const [pressent, dismiss] = useIonModal(ReportModal, {
    selectedRow: selectedReportRow,
    dismiss: closeModal,
  });

  const columns = React.useMemo(
    () => [
      {
        Header: "Timestamp",
        Cell: ({ row, data }: { data: dgroupReportQL[]; row: any }) => {
          return `${moment(
            new Date(parseInt(data[row.index].createdAt))
          ).format("LL")}`;
        },
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Verse",
        Cell: ({ row, data }: { data: dgroupReportQL[]; row: any }) => {
          return `${data[row.index].verse}`;
        },
      },
      {
        Header: "Members",
        Cell: ({ row, data }: { data: dgroupReportQL[]; row: any }) => {
          if (!data[row.index].dgroup) {
            return "-";
          }
          return `${data[row.index].dgroup.members.length}`;
        },
      },
      {
        Header: "Note",
        accessor: "note",
      },
      {
        Header: " ",
        Cell: ({ row, data }: { data: dgroupReportQL[]; row: any }) => {
          return (
            <IonButton
              size="small"
              onClick={() => {
                setselectedReportRow(data[row.index]);
                pressent();
              }}
            >
              View
            </IonButton>
          );
        },
      },
    ],
    []
  );

  return (
    <IonPage>
      <Header />

      <ContentWrapper menu="app-menu">
        <IonCard
          style={{
            marginBottom: 16,
          }}
        >
          <IonRow>
            <IonCol>
              <DateRangeInput
                state={[dateRange, setdateRange]}
                label="Date Range"
              />
            </IonCol>
          </IonRow>
        </IonCard>

        <Table
          columns={columns}
          data={data.dgroupReport}
          loading={loading}
          errorGQL={error}
        />
      </ContentWrapper>
    </IonPage>
  );
};

interface ReportModalProps {
  selectedRow: dgroupReportQL;
  dismiss: () => void;
}

const ReportModal: React.FC<ReportModalProps> = ({ selectedRow, dismiss }) => {
  const modalTab = React.useMemo(
    () => [
      {
        label: "Info",
        icon: documentText,
        component: <InfoModal />,
      },
      {
        label: "Image",
        icon: images,
        component: <ImageModal />,
      },
      {
        label: "Members",
        icon: people,
        component: <MemberModal />,
      },
      
    ],
    []
  );

  return (
    <>
      <IonHeader >
        <IonToolbar color="secondary">
          <IonButtons slot="end">
            <IonButton onClick={dismiss}>
              <IonIcon src={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <TabModal tab={modalTab}></TabModal>
    </>
  );
};

type tabModals = {
  label: string;
  icon: string;
  component: JSX.Element;
};
interface TabModalProps {
  children?: React.ReactNode;
  tab: tabModals[];
}

const TabModal: React.FC<TabModalProps> = ({ children, tab }) => {
  const [selected, setselected] = useState(tab[0].label);

  return (
    <>
      
        <TabPicker tabs={tab} selectedTab={selected}/>
  
      <IonFooter>
        <IonSegment
          value={selected}
          onIonChange={(e) => {
            if (e.detail.value) setselected(e.detail.value);
          }}
        >
          {tab.map(({ icon, label }) => (
            <IonSegmentButton
              key={label}
              color="secondary"
              value={label}
              defaultChecked
              style={{
                paddingTop: 5,
              }}
            >
              <IonLabel color="secondary">{label}</IonLabel>
              <IonIcon color="secondary" src={icon} />
            </IonSegmentButton>
          ))}
        </IonSegment>
      </IonFooter>
    </>
  );
};

interface TabPickerProps {
  tabs: tabModals[];
  selectedTab: string;
}

const TabPicker: React.FC<TabPickerProps> = ({ tabs, selectedTab }) => {
  const index = _.findIndex(tabs, function (o) {
    return o.label === selectedTab;
  });
  switch (index) {
    case 0:
      return tabs[index].component;
    case 1:
      return tabs[index].component;
    case 2:
      return tabs[index].component;
    case 3:
      return tabs[index].component;
    case 4:
      return tabs[index].component;
    case 5:
      return tabs[index].component;
    default:
      return <></>;
  }
};

export default Reports;
