import { IonItem, IonIcon, IonText, IonCol, IonInput, IonLabel } from "@ionic/react";
import _ from "lodash";
import { dgroupReportQL } from "../../../types/dgroup.types";

interface InfoItemProps<T> {
  icon?: string;
  label?: string
  path: string;
  data: T;
  setState: React.Dispatch<React.SetStateAction<T | undefined>>;
}

const InputModal: React.FC<InfoItemProps<dgroupReportQL>> = ({
  icon,
  path,
  data,
  setState,
  label
}) => {
  return (
    <IonItem>
      {icon && <IonIcon
        icon={icon}
        size="small"
        style={{
          paddingRight: 10,
        }}
      />}
      {label && <IonLabel style={{
        fontWeight: 'bold',
        marginRight: 15
      }}>{label}:</IonLabel>}
      <IonInput
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        value={_.get(data, path, "")}
        onIonChange={(ev) => {
          if (ev.detail.value) {
            setState(_.setWith({ ...data }, path, ev.detail.value, Object));
          } else {
            setState(_.setWith({ ...data }, path, "", Object));
          }
        }}
      />
    </IonItem>
  );
};

export default InputModal;
