import { Redirect, Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'


import Login from './pages/Login'
import { AuthContextProvider } from './context/auth.context'
import Pin from './pages/Login/Pin'


const Auth: React.FC = () => {
 
  return (
    <AuthContextProvider>
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <Redirect to="/" />
          <Route path="/" component={Login} exact={true} />
          <Route path="/pin" component={Pin} exact={true} />
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
    </AuthContextProvider>
  )
}

export default Auth
