const getEnumValues = (a: Object) => {
    return Object.keys(a).filter((x) => Number.isNaN(Number(x)))
}

const getEnumKeys = (a: Object) => {
    return Object.keys(a).filter((x) => !Number.isNaN(Number(x)))
}

const getEnumEntries = (a: Object) => {
    return Object.entries(a).filter((x) => Number.isNaN(Number(x[0])))
}

export {
    getEnumValues,
    getEnumKeys,
    getEnumEntries
}