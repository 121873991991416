import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonFooter,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from "@ionic/react";
import { closeSharp } from "ionicons/icons";
import _ from "lodash";
import React, { useState } from "react";

interface ReportModalProps {
  data: any;
  dismiss: () => void;
  tabs: tabModals[];
}

const ModalTab: React.FC<ReportModalProps> = ({ data, dismiss, tabs }) => {
  return (
    <>
      <IonHeader>
        <IonToolbar color="secondary">
          <IonButtons slot="end">
            <IonButton onClick={dismiss}>
              <IonIcon src={closeSharp} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <TabModal tab={tabs}></TabModal>
    </>
  );
};

type tabModals = {
  label: string;
  icon: string;
  component: JSX.Element;
};
interface TabModalProps {
  children?: React.ReactNode;
  tab: tabModals[];
}

const TabModal: React.FC<TabModalProps> = ({ children, tab }) => {
  const [selected, setselected] = useState(tab[0].label);

  return (
    <>
      <TabPicker tabs={tab} selectedTab={selected} />

      <IonFooter>
        <IonSegment
          value={selected}
          onIonChange={(e) => {
            if (e.detail.value) setselected(e.detail.value);
          }}
        >
          {tab.map(({ icon, label }) => (
            <IonSegmentButton
              key={label}
              color="secondary"
              value={label}
              defaultChecked
              style={{
                paddingTop: 5,
              }}
            >
              <IonLabel color="secondary">{label}</IonLabel>
              <IonIcon color="secondary" src={icon} />
            </IonSegmentButton>
          ))}
        </IonSegment>
      </IonFooter>
    </>
  );
};

interface TabPickerProps {
  tabs: tabModals[];
  selectedTab: string;
}

const TabPicker: React.FC<TabPickerProps> = ({ tabs, selectedTab }) => {
  const index = _.findIndex(tabs, function (o) {
    return o.label === selectedTab;
  });
  switch (index) {
    case 0:
      return tabs[index].component;
    case 1:
      return tabs[index].component;
    case 2:
      return tabs[index].component;
    case 3:
      return tabs[index].component;
    case 4:
      return tabs[index].component;
    case 5:
      return tabs[index].component;
    default:
      return <></>;
  }
};

export default ModalTab;
