import { IonRow, IonCol, IonContent } from '@ionic/react'
import React from 'react'

interface ContentWrapperProps {
    color?: string
    menu?: string
    children: React.ReactNode
    mob?: boolean
}

export const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, color = "bg", menu, mob }) => {
  
  if (mob) {
    return (
    <IonContent color={color} fullscreen id={menu}>
        <IonRow>
          <IonCol sizeLg='4' offsetLg='4'>
            {children}
          </IonCol>
        </IonRow>
      </IonContent>
    )
  }
  return (
    <IonContent color={color} fullscreen id={menu}>
      {/* <IonRow>
        <IonCol sizeLg='4' offsetLg='4'> */}
          {children}
        {/* </IonCol>
      </IonRow> */}
    </IonContent>
  )
}

export default ContentWrapper
