import {
  IonButton,
  IonHeader,
  IonInput,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import ContentWrapper from "../../components/ContentWrapper";
import { httpsCallable } from "firebase/functions";
import { useAuth, useFunctions } from "reactfire";
import "./Login.css";
import UserInfo from "../../types/user.types";
import { useState } from "react";
import { signInWithCustomToken } from "firebase/auth";
import { useHistory } from "react-router";

const Login: React.FC = () => {
  const [username, setusername] = useState("");
  const [checker, setchecker] = useState([false, ""]);

  const history = useHistory();

  const auth = useAuth();
  const functions = useFunctions();
  const signUp = httpsCallable<
    { ccfId: string },
    {
      status: "Error" | "Success";
      data: UserInfo;
      message: string;
      token: string;
    }
  >(functions, "user-userLogin");

  const [present, dismiss] = useIonLoading();

  const processLogin = () => {
    setchecker([false, ""]);
    if (!username) {
      setchecker([true, "Please input your username"]);
      return;
    }

    // if (username.length < 7) {
    //   setchecker([true, "Please input your username"])
    //   return
    // }

    history.push("/pin", {
      username,
    });

    // present()

    // signUp({ccfId}).then(({data}) => {

    //   if (data.status === "Success") {
    //     signInWithCustomToken(auth, data.token).then(() => {

    //       dismiss()
    //     })
    //     .catch((e) => {
    //       setchecker([true, "Error Login Token"])
    //       dismiss()
    //       console.log(e)
    //     })

    //   } else {
    //     setchecker([true, data.message])
    //     dismiss()
    //   }

    // }).catch(() => {
    //   dismiss()
    //   setchecker([true, "Error Login"])

    // })
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="header ion-no-border">
          <IonTitle className="header-title">CCF JAPAN</IonTitle>
        </IonToolbar>
      </IonHeader>
      <ContentWrapper mob>
        <div className="ion-padding">
          <div
            style={{
              marginTop: 200,
              background: "white",
              borderRadius: 16,
              display: "flex",
            }}
          >
            <IonInput
              className="login-search ion-text-center"
              style={{
                marginLeft: 15,
                height: 60,
                frontWeight: "Bold",
              }}
              max="10"
              min="7"
              placeholder="Username"
              onIonChange={(ev) => setusername(ev.detail.value!)}
            ></IonInput>
          </div>
        </div>
        {checker[0] && (
          <div className="login-error ion-text-center">
            <IonText color="danger">
              <small>{checker[1]}</small>
            </IonText>
          </div>
        )}
        <IonButton
          className="login-button"
          size="large"
          expand="block"
          onClick={() => processLogin()}
        >
          Login
        </IonButton>
        <div
          className="ion-text-center"
          onClick={() => history.push("/search")}
        >
          <IonText color="primary">or SignUp</IonText>
        </div>
      </ContentWrapper>
    </IonPage>
  );
};

export default Login;
