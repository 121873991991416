import { IonInput, IonIcon } from '@ionic/react'
import { search as searchIcon } from 'ionicons/icons'
import { useState } from 'react'

interface SearchBarProps {
  onSearch?: (text: string) => void
  title?: string
}

const SearchBar: React.FC<SearchBarProps> = ({onSearch, title}) => {
  const [search, setsearch] = useState("")
  return (
    <div className='ion-padding'>
      <div
        style={{
          background: 'white',
          borderRadius: 16,
          display: 'flex',
          boxShadow: 'rgb(0 0 0 / 12%) 0px 4px 16px'
        }}
      >
        <IonInput
          className='signup-search'
          style={{
            marginLeft: 15,
            height: 50
          }}
          placeholder={title ? title : 'Search'}
          onIonChange={(e) => setsearch(e.detail.value!)}
        ></IonInput>
        <div
          style={{
            marginTop: 10,
            marginRight: 14,
            padding: '7px 7.5px 7.5px 7.5px',
            background: '#45AEAC',
            borderRadius: 10,
            height: 30
          }}
          onClick={() => onSearch && onSearch(search)}
        >
          <IonIcon icon={searchIcon} color='light' />
        </div>
      </div>
    </div>
  )
}

export default SearchBar
