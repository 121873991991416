import {
  IonButton,
  IonCard,
  useIonLoading,
  useIonToast,
} from "@ionic/react";
import { useMemo } from "react";
import ContentWrapper from "../../../components/ContentWrapper";
import UserInfo from "../../../types/user.types";
import _ from "lodash";
import FormInput from "../../../components/Input/FormInput";
import FormSelect from "../../../components/Input/FormSelect";
import { useForm } from "react-hook-form";
import { useFirestore } from "reactfire";
import { doc, updateDoc } from "@firebase/firestore";
import dGroup, { dGroupCategoryEnum, dGroupFrequncyEnum, dGroupLocationEnum, dGroupTypeEnum } from "../../../types/dgroup.types";

interface UserInfoC extends UserInfo {
  createdAt: string;
}

interface ViewProfileProps {
  data: dGroup;
}

type formFields = {
    name: string;
    label?: string;
    accessor: string;
    type?: 'text' | 'date' | 'tel';
}
type formFieldSelect = {
    name: string;
    label?: string;
    accessor: string;
    type?: 'text' | 'date' | 'tel';
    select: {[index: string]: string | number} | {[index: number]: string | number}
}

const EditDetail: React.FC<ViewProfileProps> = ({ data }) => {
  const { control, handleSubmit } = useForm();

  const [presentL, dismissL] = useIonLoading()
  const [presentT] = useIonToast()

  const formFields = useMemo<formFields[]>(
    () => [
      {
        name: "name",
        label: "Name",
        accessor: "name",
      },
      {
        name: "date",
        label: "Date",
        accessor: "date",
        type: 'date'
      }
     
    ],
    [data]
  );

  const formFieldsSelect = useMemo<formFieldSelect[]>(
    () => [
      {
        name: "type",
        label: "Type",
        accessor: "type",
        select: dGroupTypeEnum
      },
      {
        name: "category",
        label: "Category",
        accessor: "category",
        select: dGroupCategoryEnum
      },
      {
        name: "frequently",
        label: "Frequently",
        accessor: "frequently",
        select: dGroupFrequncyEnum
      },
      {
        name: "location",
        label: "Location",
        accessor: "location",
        select: dGroupLocationEnum
      }
      
    ],
    [data]
  );

  const firestore = useFirestore();
  const leader = _.find(_.get(data, 'members', []), function(o) { return o.type !== 0; });
  const dgroupRef = doc(firestore, "users", `${_.get(leader, 'uid', '')}/dgroup/${_.get(data, 'dgroupId', '')}`);

  const registerUser = (data: Object) => {
   // presentL()
    const d = Object.entries(data).map((d) => {
      if (d[1]) {
        return [`${d[0]}`, `${d[1]}`]
      }
    })
    .filter(Boolean) as [string, string][]

    const parseD = Object.fromEntries(d)
    console.log("🚀 ~ file: EditDetail.tsx ~ line 84 ~ registerUser ~ parseD", parseD)
 
    // if (_.get(parseD, 'firstName', "") && _.get(parseD, 'lastName', "")) {
    //   processSave({
    //     displayName: `${_.get(parseD, 'firstName', '')} ${_.get(parseD, 'lastName', '')}`,
    //     ...parseD
    //   })
    // } else if (_.get(parseD, 'lastName', "")) {
    //   processSave({
    //     displayName: `${_.get(data, 'firstName', '')} ${_.get(parseD, 'lastName', '')}`,
    //     ...parseD
    //   })
    // } else if (_.get(parseD, 'lastName', "")) {
    //   processSave({
    //     displayName: `${_.get(data, 'firstName', '')} ${_.get(parseD, 'lastName', '')}`,
    //     ...parseD
    //   })
    // } else {
    //   processSave(parseD)
    // }
    //processSave(parseD)
  };

  const processSave = (toSave: {
    [k: string]: string;
}) => {
    updateDoc(dgroupRef, toSave)
      .then((d) => {
        setTimeout(() => {
          dismissL()
          presentT({
            message: "Save Successfully.",
            duration: 700
          })
        }, 500)
      })
      .catch((d) => setTimeout(() => {
          dismissL()
          presentT({
            message: "Fail to save.",
            duration: 700,
            color: 'danger'
          })
        }, 500))
  }


  return (
    <ContentWrapper menu="app-menu">

    

      <form onSubmit={handleSubmit(registerUser)}>
        <IonCard
          style={{
            marginTop: 16,
          }}
        >

          {formFields.map((field, index) => (
            <FormInput
              {...field}
              control={control}
              key={index+field.name}
              value={_.get(data, field.accessor, "")}
            />
          ))}
          {formFieldsSelect.map((field, index) => (
            <FormSelect
              {...field}
              control={control}
              key={index+field.name}
              value={_.get(data, field.accessor, "")}
            />
          ))}

        </IonCard>
        <div style={{
          margin: '0 16px'
        }}>
          <IonButton expand="block" type="submit">Change</IonButton>
        </div>
      </form>

      
    </ContentWrapper>
  );
};

export default EditDetail;
