import { IonButton, IonCol, IonIcon, IonRow, IonText } from "@ionic/react";
import { caretBack, caretForward, playSkipBack, playSkipForward } from "ionicons/icons";
import { useEffect } from "react";
import { Row, TableState } from "react-table";

interface PaginationProps {
  PageFunction: {
    page: Row<object>[]
    canPreviousPage: boolean
    canNextPage: boolean
    pageOptions: number[]
    pageCount: number
    gotoPage: (updater: number | ((pageIndex: number) => number)) => void
    nextPage: () => void
    previousPage: () => void
    setPageSize: (pageSize: number) => void
    state: TableState<object>
  }
}

const Pagination: React.FC<PaginationProps> = ({ PageFunction }) => {
 
  const {
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state
  } = PageFunction

  

  return (
      <IonRow>
        <IonCol className="pagination">
          <IonButton fill="clear" size="small" onClick={() => gotoPage(0)}>
            {canPreviousPage && <IonIcon src={playSkipBack}/>}
          </IonButton>
          <IonButton fill="clear" onClick={previousPage}>
            {canPreviousPage && <IonIcon src={caretBack}/>}
          </IonButton>
          <div>
            <IonText>
              {state.pageIndex + 1}
            </IonText>
          </div>
          <IonButton fill="clear"  onClick={nextPage}>
            {canNextPage && <IonIcon src={caretForward}/>}
          </IonButton>
          <IonButton fill="clear" size="small"  onClick={() => gotoPage(pageCount - 1)}>   
            {canNextPage && <IonIcon src={playSkipForward}/>}
          </IonButton>
        </IonCol>
      </IonRow>
  );
};

export default Pagination;


