import { getAuth } from "firebase/auth";
import {
  collection,
  doc,
  DocumentData,
  DocumentReference,
} from "firebase/firestore";
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import {
  ObservableStatus,
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
  useStorage,
} from "reactfire";
import UserInfo from "../types/user.types";
import { ApolloQueryResult, gql, OperationVariables, useQuery } from "@apollo/client";
import { getDownloadURL, getStorage, ref as sRef } from "firebase/storage";

import profileDefault from "../img/default-profile.jpg";

interface UserContextInterface {
  userData: UserInfo;
  setuserData: (userData: UserInfo) => void;
  ccfId?: string;
  setccfId: (s: string) => void;
  userDoc: ObservableStatus<DocumentData>;
  userRef: DocumentReference<DocumentData>;
  profileImg: string;
  refetchProfileImg: () => void;
}

const UserContext = createContext({} as UserContextInterface);
const UserContextProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [userData, setuserData] = useState(user);
  const [ccfId, setccfId] = useState("");
  const [profileImg, setprofileImg] = useState(profileDefault);

  const firestore = useFirestore();
  const storage = useStorage();

  //const [selectedDgroup, setselectedDgroup] = useState<DgroupTypes>()

  const auth = getAuth();

  const userRef = doc(firestore, "users", auth.currentUser!.uid);

  const userDoc = useFirestoreDocData(userRef);

  const dGroupRef = collection(
    firestore,
    `users/${auth.currentUser!.uid}/dgroup`
  );

  // const { data: dGroupDatas } = useFirestoreCollectionData(dGroupRef, {
  //   initialData: [],
  // });

  // const {
  //   data: dGroupDatass = { getDgroup: [] },
  //   refetch: refetchDgroup,
  // } = useQuery<{
  //   getDgroup: DocumentData[];
  // }>(GET_DGROUP, {
  //   variables: {
  //     uid: userDoc.data.uid,
  //   },
  // });

  const pStorage = getStorage();

  const profileRef = sRef(pStorage, `${'userDoc.data.uid'}/profile.png`);

  const processImgProfile = useCallback(() => {
    getDownloadURL(profileRef)
      .then((data) => {
        setprofileImg(data);
      })
      .catch(() => {
        setprofileImg(profileDefault);
      });
  }, [profileRef, setprofileImg]);

  useEffect(() => {
    processImgProfile();
  }, [processImgProfile]);

  const refetchProfileImg = () => {
    processImgProfile();
  };

  // const addMember = (member: UserInfo) => {
  //     const docRef = doc(dGroupRef, selectedDgroup!.dId)
  //     try {
  //       updateDoc(docRef, {
  //         members: arrayUnion({
  //           ccfId: member.ccfId,
  //           uid: member.uid,
  //           name: member.displayName,
  //           type: 1
  //         })
  //       })
  //       setselectedDgroup({
  //         ...selectedDgroup!,
  //         members: [
  //           ...selectedDgroup!.members,
  //           {
  //             ccfId: member.ccfId!,
  //             uid: member.uid!,
  //             name: member!.displayName!,
  //             type: 1
  //           }
  //         ]
  //       })
  //       return true
  //     } catch (e: any) {
  //       throw Error(e)
  //     }
  // }

  //   const deleteMember = (member: dGroupMembers) => {
  //     const docRef = doc(dGroupRef, selectedDgroup!.dId)
  //     try {
  //       updateDoc(docRef, {
  //         members: arrayRemove({
  //           ccfId: member.ccfId,
  //           uid: member.uid,
  //           name: member.name,
  //           type: 1
  //         })
  //       })
  //       setselectedDgroup({
  //         ...selectedDgroup!,
  //         members: [
  //           ..._.filter(selectedDgroup!.members, (o) => o.ccfId !== member.ccfId)
  //         ]
  //       })
  //       return true
  //     } catch (e: any) {
  //       throw Error(e)
  //     }
  // }

  // const dGroupIds = dGroupDatas.map((d) => d.dgroupId);
  // const dgroupData = [...dGroupDatas, ...dGroupDatass.getDgroup.filter((a) => a && !dGroupIds.includes(a.dgroupId))
  //   .filter(Boolean)
  // ]
  return (
    <UserContext.Provider
      value={{
        userData,
        setuserData,
        ccfId,
        setccfId,
        userDoc,
        userRef,
        profileImg,
        refetchProfileImg
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const user = {
  firstName: "",
  lastName: "",
  birthday: "",
  ccf_location: "",
  phone: "",
  postalCode: "",
  prefecture: "",
  town: "",
  city: "",
  addressLine: "",
  accepted: false,
  dGroupLeader: "",
  username: "",
};

const dgroup = {
  name: "",
  day: "",
  time: "",
  frequently: "",
  category: "",
  type: "",
  location: "",
  ccfId: "",
  dgroupId: "",
  uid: "",
  members: [],
};

const GET_DGROUP = gql`
  query Query($uid: ID!) {
    getDgroup(uid: $uid) {
      members {
        type
        name
        location
        phone
        uid
        ccfId
      }
      name
      day
      time
      frequently
      category
      type
      location
      ccfId
      dgroupId
    }
  }
`;

export { UserContext, UserContextProvider };
