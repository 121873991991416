import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonMenuButton,
  IonBackButton,
  IonButtons,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonToggle,
} from "@ionic/react";
import { arrowBack, moon } from "ionicons/icons";
import { useState } from "react";
import "./Header.css";

interface HeaderProps {
  hasBack?: Boolean;
  toBack?: string;
}

const Header: React.FC<HeaderProps> = ({ hasBack = true, toBack }) => {
  const toggleDarkModeHandler = () => {
    document.body.classList.toggle("dark");
  };

  return (
    <IonHeader>
      <IonToolbar className="header ion-no-border">
        {hasBack && (
          <IonButtons
            slot="start"
            style={{
              marginLeft: 10,
            }}
          >
            {toBack ? (
              <IonButton
                fill="clear"
                color="dark"
                routerLink={toBack}
                routerDirection="back"
              >
                <IonIcon src={arrowBack} />
              </IonButton>
            ) : (
              <IonBackButton
                text=""
                icon={arrowBack}
                color="dark"
                style={{
                  fontSize: 13,
                }}
              />
            )}
          </IonButtons>
        )}
        <IonTitle className="header-title">CCF JAPAN</IonTitle>

        <IonMenuButton
          autoHide={true}
          slot="end"
          color="dark"
          menu="app-menu"
        ></IonMenuButton>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
