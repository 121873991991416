import { ReactComponentOrElement } from "@ionic/react";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import React, { createContext, ReactNode, useState } from "react";
import UserInfo from '../types/user.types'

interface AuthData {
  status: "Error" | "Success"
  token: string
  data: UserInfo
}

interface AuthContextInterface {
  authData?: AuthData;
  setauthData: (userData: AuthData) => void;
  loginSignUpUser: () => Promise<string>
}

const AuthContext = createContext({} as AuthContextInterface);

const AuthContextProvider: React.FC<{children: ReactNode}> = ({ children }): JSX.Element => {
    const [authData, setauthData] = useState<AuthData>()

    const auth = getAuth()
    
   
    const loginSignUpUser = async () => {
      if (!authData?.token) {
          throw new Error("No Token to Login")
      } 

      await signInWithCustomToken(auth, authData?.token)

      return "User Will Now Sign-in"
      
    }


  return (
    <AuthContext.Provider
      value={{
        authData, setauthData,
        loginSignUpUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider};
