import {
  IonCol,
  IonContent,
  IonPage,
  IonRow,
  IonSpinner
} from '@ionic/react'

const SplashScreen: React.FC = () => {
  return (
    <IonPage>
      <IonContent color='tertiary'>
        <IonRow>
          <IonCol sizeLg='4' offsetLg='4'>
            <div className='center'>
              <div className='loading'>
                <IonSpinner />
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  )
}

export default SplashScreen
