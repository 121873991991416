import ContentWrapper from "../../components/ContentWrapper";
import { useContext, useEffect, useState } from "react";
import { StateContext } from "../../context/state.context";
import _ from "lodash";

import "./OrgChart.css";
import { IonCol, IonImg, IonRow, useIonViewWillEnter } from "@ionic/react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

interface InfoModalProps {
  //reportData: dgroupReportQL;
}

const ImageModal: React.FC<InfoModalProps> = () => {
  const { selectedReportRow, setselectedReportRow } = useContext(StateContext);
  const [image, setimage] = useState<string>();

  const storage = getStorage();
  const m = _.get(selectedReportRow, "dgroup.members", []);
  const l = _.findIndex(m, function (o: { type: string }) {
    return o.type === "0";
  });
  
  const leader =_.get(selectedReportRow, "dgroup.members.1", "");
  const profileRef = ref(
    storage,
    `${leader!.uid}/dgroup/${selectedReportRow!.dgroupId}/${
      selectedReportRow!.reportId
    }`
  );

  useEffect(() => {
    getDownloadURL(profileRef).then((data) => {
      setimage(data);
    });
  }, [selectedReportRow]);

  if (m.length === 0) {
    return <></>;
  }
  
 
  return (
    <ContentWrapper>
      <IonRow>
        <IonCol>
          <div
            style={{
              display: "flex",
            }}
          >
            <IonImg src={image} />
          </div>
        </IonCol>
      </IonRow>
    </ContentWrapper>
  );
};

export default ImageModal;
