import {
  IonButton,
  IonCard,
  IonCardContent,
  IonChip,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonRow,
  IonText,
} from "@ionic/react";
import { bookmark, navigate } from "ionicons/icons";
import ContentWrapper from "../../components/ContentWrapper";
import InputModal from "./Modal/InputModal";
import ItemModal from "./Modal/ItemModal";
import {
  dGroupMember,
  dGroupMemberType,
  dGroupReport,
  dgroupReportQL,
} from "../../types/dgroup.types";
import { useContext, useEffect, useMemo } from "react";
import { StateContext } from "../../context/state.context";
import _, { filter } from "lodash";
import SelectModal from "./Modal/SelectModal";
import TextAreaModal from "./Modal/TextAreaModal";

import OrganizationChart from "@dabeng/react-orgchart";

import "./OrgChart.css";

interface InfoModalProps {
  //reportData: dgroupReportQL;
}

const MemberModal: React.FC<InfoModalProps> = () => {
  const { selectedReportRow, setselectedReportRow } = useContext(StateContext);
  const mem = _.get(selectedReportRow!, "members", []) as dGroupMember[]
  // const org = useMemo(() => {
  //   const mem = _.get(selectedReportRow!, "members", []);
  //   console.log("🚀 ~ file: MemberModalContent.tsx ~ line 42 ~ org ~ mem", mem)

  //   let leader = _.findIndex(mem, function (o: { type: string }) {
  //     return o.type === "0";
  //   });
  //   let members = mem
  //     .map((n: any, index: number) => {
  //       if (index !== leader) {
  //         return n;
  //       }
  //     })
  //     .filter(Boolean);

  //   let s = _.setWith({ ...mem[leader] }, "children", [...members], Object);
  //   console.log("🚀 ~ file: MemberModalContent.tsx ~ line 55 ~ org ~ s", s)

  //   return s;
  // }, [selectedReportRow]);

  return (
    <ContentWrapper>
      {/* <OrganizationChart
        datasource={JSON.parse(JSON.stringify(sample[0]))}
        chartClass="org-chart"
        containerClass="org-container"
        NodeTemplate={MyNode}
      /> */}

      <IonList>
        <IonListHeader>Attendee</IonListHeader>
        {
        mem.map((m, key) => <IonItem>{_.get(m, 'name')}</IonItem>)
      }
      </IonList>
      
    </ContentWrapper>
  );
};
const MyNode: React.FC<any> = ({ nodeData }) => {
  return (
    <IonCard>
      <IonCardContent>
        <div>
          <IonLabel>{nodeData.firstName}</IonLabel>

          <div>
            <IonLabel
              style={{
                fontSize: 9,
              }}
            >
              {dGroupMemberType[nodeData.type]}
            </IonLabel>
          </div>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default MemberModal;


const sample = [
  {
    "firstName": "Pin1",
    "lastName": "Test",
    "children": [
      {
        "firstName": "Airo-Jireh",
        "lastName": "Abila"
      },
      {
        "firstName": "Pin1",
        "lastName": "Test"
      }
    ]
  },
  {
    "firstName": "Pin2",
    "lastName": "Test",
    "children": []
  },
  {
    "firstName": "Pin3",
    "lastName": "Test",
    "children": []
  }
]