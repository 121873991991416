import {
  IonButton,
  IonCard,
  IonCol,
  IonHeader,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonLoading
} from '@ionic/react'
import ContentWrapper from '../../components/ContentWrapper'
import { httpsCallable } from 'firebase/functions'
import { useAuth, useFunctions } from 'reactfire'
import './Login.css'
import UserInfo from '../../types/user.types'
import { useState } from 'react'
import { signInWithCustomToken } from 'firebase/auth'
import { useHistory, useLocation } from 'react-router'
import _ from 'lodash'
import Header from '../../components/Header'

const Pin: React.FC = () => {
  
  const [checker, setchecker] = useState([false, ""])
  const [pin, setpin] = useState("")

  const location = useLocation()

  const username = _.get(location, 'state.username', "")

  const auth = useAuth()
  const functions = useFunctions();
  const signUp = httpsCallable<{username: string, pin: string}, {
    status: "Error" | "Success",
    data: UserInfo,
    message: string,
    token: string
  }>(functions, process.env.NODE_ENV === "development" ? "user-userLogin" : "user-adminLogin");

  

  const [present, dismiss] = useIonLoading();

  const processLogin = () => {
    setchecker([false, ""])
    if (!username) {
      setchecker([true, "Please input CCF App ID"])
      return
    }

    // if (username.length < 7) {
    //   setchecker([true, "Please input CCF App ID"])
    //   return
    // } 

    present()
    
    signUp({username, pin}).then(({data}) => {
   
      if (data.status === "Success") {
        signInWithCustomToken(auth, data.token).then(() => {
          console.log(data)
          dismiss()
        })
        .catch((e) => {
          setchecker([true, "Error Login Token"])
          dismiss()
          console.log(e)
        })

        
      } else {
        setchecker([true, data.message])
        dismiss()
      }
        
    }).catch(() => {
      dismiss()
      setchecker([true, "Error Login"])
      
    })

  }
  
  return (
    <IonPage>
      <Header toBack='/'/>
      <ContentWrapper mob>
        <div className='ion-padding'>
        <IonCard
          style={{
            background: "white",
            borderRadius: 16,
            cursor: "pointer"
          }}
        >
          <div className="ion-text-center" style={{
            margin: '20px'
          }}>
            <div style={{
            margin: '20px'
          }}
          className="ion-text-center"
          >
            <IonText
              style={{
                letterSpacing: 15,
                fontWeight: 'Bold',
                fontSize: 20,
                paddingLeft: 15
              }}
            >{`${pinSet(_.get(pin, '0'))}${pinSet(_.get(pin, '1'))}${pinSet(_.get(pin, '2'))}${pinSet(_.get(pin, '3'))}`}</IonText>
            </div>

            <PinButton pin={pin} setpin={setpin} />
          </div>


        </IonCard>
        
        </div>
            {checker[0] && (
              <div className='login-error ion-text-center'>
                <IonText color='danger'>
                  <small>{checker[1]}</small>
                </IonText>
              </div>
            )}
        <IonButton className='login-button' size='large' expand='block' onClick={() => processLogin()}>
          Login
        </IonButton>
    
      </ContentWrapper>
    </IonPage>
  )
}

const pinSet = (p: string) => {
  if (p) return '*'
  else return '-'
}


interface PinButtonProps {
  pin: string
  setpin: React.Dispatch<React.SetStateAction<string>>
}

const PinButton: React.FC<PinButtonProps> = ({pin, setpin}) => {
  const num = [1,2,3,4,5,6,7,8,9]
  return <div>
    <IonRow>
      {num.map((i, key) => <IonCol key={key} size='4'>
        <IonCard className="ion-padding" style={{
          margin: 5
        }}
        onClick={() => {
          if (pin.length <= 3) {
            setpin(pin + i)
          }
        }}
        >{i}</IonCard>
      </IonCol>)}
      <IonCol size='4' offset="4">
        <IonCard className="ion-padding" style={{
          margin: 5
        }}
        onClick={() => {
          if (pin.length <= 3) {
            setpin(pin + 0)
          }
        }}
        >0</IonCard>
      </IonCol>
      <IonCol size='4'>
        <IonCard className="ion-padding" style={{
          margin: 5
        }}
        onClick={() => {
          
          setpin('')
          
        }}
        >Clear</IonCard>
      </IonCol>
    </IonRow>
  </div>
}

export default Pin
