import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React, { FC } from "react";
import { Control, Controller } from "react-hook-form";

export interface InputProps {
  name: string;
  control?: Control;
  label?: string;
  component?: JSX.Element;
  value?: string
  type?: "text" | 'date' | 'tel'
}

const FormInput: FC<InputProps> = ({ name, control, component, label, value: valueV, type = "text" }) => {
  return (
    <>
      <IonItem>
        {label && <IonLabel position="floating">{label}</IonLabel>}
        <Controller
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <IonInput
              onBlur={onBlur} // notify when input is touched
              onIonChange={onChange} // send value to hook form
              ref={ref}
              value={value ? value : valueV}
              name={name}
              type={type}
            />
          )}
          name={name}
          control={control}
        />
      </IonItem>
    </>
  );
};

export default FormInput;
